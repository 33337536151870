<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Payment Transaction</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Payment Transaction</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="overlay" v-if="is_loading">
        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="overlay" v-if="is_loading">
              <i class="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
            <div class="card-header">
              <h3 class="card-title">Detail</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'PaymentTransactionList'}">
                  <i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body table-responsive p-0">
              <table class="table table-striped">
                <tbody>
                
                  <tr>
                    <td><b>ID</b></td>
                    <td>{{ value.id }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Transaction type</b></td>
                    <td>{{ value.transactionType }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Transaction-Id</b></td>
                    <td>{{ value.transactionId }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Merchant Transaction-Id</b></td>
                    <td>{{ value.merchantTransactionId }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Gateway Transaction-Id</b></td>
                    <td>{{ value.gatewayTransactionId }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Account-Id</b></td>
                    <td>{{ value.accountId }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Amount</b></td>
                    <td>{{ value.amount }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Currency</b></td>
                    <td>{{ value.currency }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Processed Amount</b></td>
                    <td>{{ value.processedAmount }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Processed currency</b></td>
                    <td>{{ value.processedCurrency }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Payment Status</b></td>
                    <td>{{ value.paymentStatus }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Gateway Error-code</b></td>
                    <td>{{ value.gatewayErrorCode }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Gateway Error message</b></td>
                    <td>{{ value.gatewayErrorMsg }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Remarks</b></td>
                    <td>{{ value.remarks }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Channel</b></td>
                    <td>{{ value.channel }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Merchant Callback-URL</b></td>
                    <td>{{ value.merchantCallbackUrl }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Gateway-Id</b></td>
                    <td>{{ value.gatewayId }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Merchant-Id</b></td>
                    <td>{{ value.merchantId }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Gateway Parameters</b></td>
                    <td>{{ value.gatewayParams }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Additional Properties</b></td>
                    <td>{{ value.additionalProperties }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Order-Id</b></td>
                    <td>{{ value.orderBookingId }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Reference Payment-Id</b></td>
                    <td>{{ value.referencePaymentId }}</td>
                  </tr>
                  
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
      
    </section>
  </div>
</template>

<script>
import MasterLayout from "@/components/layouts/MasterLayoutComponent.vue";
import {DepartmentsAPI} from "@/services/api";
import axios from "axios";


export default {
  name: "PaymentTransactionShow",
  data: () => ({
    value: {
      
    },
    pre: {
      
    },
    is_loading: false
  }),
  mounted: function () {
    this.initialize();
  },
  methods: {
    
    async initialize() {
      this.is_loading = true;
      this.params = this.$route.params;

      

      await this.getDetail(this.params.id).then(detail => {
        this.value = detail;
      });

      this.is_loading = false;
    },
    getDetail: function (id) {
      let _this = this;
      return new Promise(resolve => {
        let api_url = "/payment_transactions/" + id;
        _this.is_loading = true;
        axios.get(api_url)
          .then(response => {
            _this.value = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            console.log("error " + error);
            resolve(error);
          })
          .finally(() => {
            _this.is_loading = false;
          });
      });
    },
  },
  created() {
    this.$emit("update:layout", MasterLayout);
  },
}
</script>
        